@font-face {
    font-family: 'GT-Walsheim-Bold';
    src: url('./GT-Walsheim-Bold.eot');
    src: url('./GT-Walsheim-Bold.eot?#iefix') format('embedded-opentype'),
        url('./GT-Walsheim-Bold.woff') format('woff'),
        url('./GT-Walsheim-Bold.ttf') format('truetype'),
        url('./GT-Walsheim-Bold.svg#CreativeBlockBBBold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GT-Walsheim-Regular';
    src: url('./GT-Walsheim-Regular.eot');
    src: url('./GT-Walsheim-Regular.eot?#iefix') format('embedded-opentype'),
        url('./GT-Walsheim-Regular.woff') format('woff'),
        url('./GT-Walsheim-Regular.ttf') format('truetype'),
        url('./GT-Walsheim-Regular.svg#CreativeBlockBBBold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GT-Walsheim-Light';
    src: url('./GT-Walsheim-Light.eot');
    src: url('./GT-Walsheim-Light.eot?#iefix') format('embedded-opentype'),
        url('./GT-Walsheim-Light.woff') format('woff'),
        url('./GT-Walsheim-Light.ttf') format('truetype'),
        url('./GT-Walsheim-Light.svg#CreativeBlockBBBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'custom-icons';
    src: url('./icons/custom-icons.eot?xcb4lu');
    src: url('./icons/custom-icons.eot?xcb4lu#iefix') format('embedded-opentype'),
        url('./icons/custom-icons.ttf?xcb4lu') format('truetype'),
        url('./icons/custom-icons.woff?xcb4lu') format('woff'),
        url('./icons/custom-icons.svg?xcb4lu#custom-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}
