/**
* This file exists because the inline style in the html received from the backend is incomplete.
* The table comes with the aptly named class "table" which is fortunately not used anywhere else.
*/
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
}

.table {
    font-size: 14px;
    font-family: "GT-Walsheim-Light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.table strong {
    font-weight: 700;
}

.table strong {
    font-family: "GT-Walsheim-Bold", Helvetica, Arial, "Lucida Grande", sans-serif;
}